import * as React from "react"
import { Link } from "gatsby"
import MainLayout from '../components/mainLayout'

// markup
const NotFoundPage = () => {
  return (
    <main>
      <MainLayout>
        <h1>404 - Seite nicht gefunden</h1>

        <p>Hier gibt es wenig zu sehen. Aber du kannst direkt im <Link to="/kapitel-1">ersten Kapitel</Link> loslegen.</p>
        
        <Link to="/">oder zur Startseite</Link>
      </MainLayout>
    </main>
  )
}

export default NotFoundPage
